export function SchemaOrg() {
  const schema = {
    "@context": "https://schema.org",
    "@type": "Person",
    "name": "Theo Goodman",
    "givenName": "Theo",
    "familyName": "Goodman",
    "url": "https://www.hellogoodman.com",
    "image": "https://www.hellogoodman.com/images/theo-open-graph.jpg",
    "jobTitle": "Co-Founder",
    "worksFor": {
      "@type": "Organization",
      "name": "Half Lemons"
    },
    "sameAs": [
      "https://www.linkedin.com/in/theo-goodman/", 
    ],
    "knowsAbout": [
      "Software Development",
      "Product Management",
      "iOS Development",
      "User Experience Design"
    ]
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
} 