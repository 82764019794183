import { useEffect } from 'react';
import { MetaTags } from '../components/MetaTags';
import { Footer } from '../components/Footer';
// Import images using ES Modules
import halfLemonsImage from '../images/half-lemons.png';
import dinnersAppImage from '../images/dinners-app.png';
import streakPulseImage from '../images/streak-pulse.png';
import thinkGloballyImage from '../images/think-globally.jpg';

export function About() {
  useEffect(() => {
    document.title = "About Theo Goodman - Background & Experience";
    const metaDescription = document.createElement('meta');
    metaDescription.name = "description";
    metaDescription.content = "Learn more about Theo Goodman's professional background, skills, and experience in software development and technology. Co-founder of Half Lemons and creator of innovative iOS apps.";
    document.head.appendChild(metaDescription);
  }, []);

  return (
    <>
      <MetaTags
        title="About Theo Goodman - Background & Experience"
        description="Learn more about Theo Goodman's professional background, skills, and experience in software development and technology. Co-founder of Half Lemons and creator of innovative iOS apps."
        path="about"
        image="/images/theo-open-graph.jpg"
      />
      <main className="flex-1 flex flex-col items-center px-14 py-16 max-w-6xl mx-auto">
        <div className="mb-16">
          <h1 className="text-7xl font-light mb-16">
            Hi. I'm Theo. <span className="ml-2">👋</span>
          </h1>
          
          <p className="text-lg mb-8">
            I'm a product manager turned engineer. I enjoy building solutions
            to life's problems, and shipping products that touch as many
            people as possible. Currently I work at Half Lemons, a startup I
            co-founded that is reinventing home cooking. I also build &
            maintain a portfolio of iOS apps. I have a passion for learning, and
            good UX. Also Family, pets, and houseplants.
          </p>
          
          <p className="text-lg mb-8">
            Check out my apps.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-52 gap-y-20 w-full">
          {/* Half Lemons */}
          <div className="space-y-4">
            <img 
              src={halfLemonsImage} 
              alt="Half Lemons iOS App by Theo Goodman - Recipe finder using ingredients you have" 
              className="w-full aspect-square object-cover rounded-2xl"
            />
            <h3 className="text-xl font-medium">Half Lemons</h3>
            <p>Find recipes using your ingredients. Available on the App Store.</p>
            <a href="https://www.halflemons.com" className="text-gray-300 hover:text-white transition-colors underline">www.halflemons.com</a>
          </div>

          {/* Dinners App */}
          <div className="space-y-4">
            <div className="w-full aspect-square bg-[#ff5733] flex items-center justify-center rounded-2xl">
              <img 
                src={dinnersAppImage} 
                alt="Dinners App by Theo Goodman - Tinder-style recipe matching app for families" 
                className="w-full aspect-square object-cover rounded-2xl"
              />
            </div>
            <h3 className="text-xl font-medium">Dinners App</h3>
            <p>Tinder for recipes. Swipe & find matches. Discover recipes the whole house likes. Available on the App Store.</p>
            <a href="https://www.dinnersapp.com" className="text-gray-300 hover:text-white transition-colors underline">www.dinnersapp.com</a>
          </div>

          {/* Streak Pulse */}
          <div className="space-y-4">
            <img 
              src={streakPulseImage} 
              alt="Streak Pulse iOS App by Theo Goodman - Gamified task management and habit tracking" 
              className="w-full aspect-square object-cover rounded-2xl"
            />
            <h3 className="text-xl font-medium">Streak Pulse</h3>
            <p>Gamify your to-do list. Check off items, grow your streak. Available on the App Store.</p>
            <a href="https://www.streakpulse.com" className="text-gray-300 hover:text-white transition-colors underline">www.streakpulse.com</a>
          </div>

          {/* #4 - Coming Soon */}
          <div className="space-y-4">
            <div className="w-full aspect-square bg-gray-700 flex items-center justify-center rounded-2xl">
              <div className="w-1/3 h-1/3 bg-white rounded-lg"></div>
            </div>
            <h3 className="text-xl font-medium">#4 - Coming Soon</h3>
            <p>An easy to use food diary. Take control of your food allergies using AI.</p>
          </div>

          {/* Think Globally */}
          <div className="space-y-4">
            <img 
              src={thinkGloballyImage} 
              alt="Think Globally Live Locally by Theo Goodman - Curated travel guide platform" 
              className="w-full aspect-square object-cover rounded-2xl"
            />
            <h3 className="text-xl font-medium">Think Globally, Live Locally</h3>
            <p>A refreshingly original take on the curated travel guide. It's the product I wish existed every time I'm planning my travels.</p>
            <a href="https://www.thinkgloballylivelocally.com" className="text-gray-300 hover:text-white transition-colors underline">www.thinkgloballylivelocally.com</a>
          </div>
        </div>
      </main>
      
      <Footer />
    </>
  );
}