// import React from 'react';
// import { Mail, Linkedin } from 'lucide-react';
import { SchemaOrg } from '../components/SchemaOrg';
import { MetaTags } from '../components/MetaTags';
import { Footer } from '../components/Footer';

export function Home() {
  return (
    <>
      <MetaTags
        title="Theo Goodman - Developer & Technology Professional"
        description="Official website of Theo Goodman - Software developer, technology professional, and digital innovator. Learn about my work, projects, and expertise."
        path=""
        image="/images/theo-open-graph.jpg"
        lang="en"
        alternateLanguages={{
          "es": "https://www.hellogoodman.com/es",
          "fr": "https://www.hellogoodman.com/fr"
        }}
      />
      <SchemaOrg />
      <div className="fixed inset-0 flex flex-col" style={{ top: '96px' }}>
        <main className="flex-1 flex flex-col items-center justify-center text-center px-4">
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-7xl lg:text-8xl font-light mb-4">THEO GOODMAN</h1>
            <h2 className="text-xl tracking-wider mb-16">CO-FOUNDER @ HALF LEMONS</h2>
            <div className="w-16 h-px bg-white mb-16"></div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}