import { Mail, Linkedin } from 'lucide-react';

export function Footer() {
  return (
    <footer className="p-4">
      <div className="flex justify-center space-x-6">
        <a href="https://www.linkedin.com/in/theo-goodman/" className="hover:text-gray-300 transition-colors">
          <Linkedin size={28} />
        </a>
        
        <a href="mailto:theo@hellogoodman.com" className="hover:text-gray-300 transition-colors">
          <Mail size={28} />
        </a>
      </div>
    </footer>
  );
} 