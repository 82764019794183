import { Link, useLocation } from 'react-router-dom';

export function Navigation() {
  const location = useLocation();
  
  return (
    <nav className="p-8">
      <ul className="flex justify-center space-x-8">
        <li>
          <Link 
            to="/" 
            className={`transition-colors ${location.pathname === '/' ? 'text-white font-bold' : 'text-gray-300'}`}
          >
            HOME
          </Link>
        </li>
        <li>
          <Link 
            to="/about" 
            className={`transition-colors ${location.pathname === '/about' ? 'text-white font-bold' : 'text-gray-300'}`}
          >
            ABOUT
          </Link>
        </li>
        <li>
          <Link 
            to="/contact" 
            className={`transition-colors ${location.pathname === '/contact' ? 'text-white font-bold' : 'text-gray-300'}`}
          >
            CONTACT
          </Link>
        </li>
      </ul>
    </nav>
  );
}