import React, { useState, useEffect, useRef } from 'react';
import { MetaTags } from '../components/MetaTags';
import { Footer } from '../components/Footer';
import emailjs from '@emailjs/browser';

export function Contact() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus('idle');
    
    try {
      // Replace these with your actual EmailJS service ID, template ID, and public key
      const serviceId = 'service_hd117sa';
      const templateId = 'template_wfwjser';
      const publicKey = 'EPUoW3dcMG8SezwNb';
      
      const templateParams = {
        from_email: email,
        message: message,
      };
      
      await emailjs.send(serviceId, templateId, templateParams, publicKey);
      
      // Reset form and show success message
      setEmail('');
      setMessage('');
      setSubmitStatus('success');
    } catch (error) {
      console.error('Error sending message:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    document.title = "Contact Theo Goodman - Get In Touch";
    const metaDescription = document.createElement('meta');
    metaDescription.name = "description";
    metaDescription.content = "Connect with Theo Goodman. Reach out for collaboration opportunities, project inquiries, or professional networking.";
    document.head.appendChild(metaDescription);
    
    // Initialize EmailJS
    // Replace with your actual EmailJS public key
    emailjs.init('EPUoW3dcMG8SezwNb');
  }, []);

  return (
    <>
      <MetaTags
        title="Contact Theo Goodman - Get In Touch"
        description="Connect with Theo Goodman. Reach out for collaboration opportunities, project inquiries, or professional networking."
        path="contact"
        image="/images/theo-open-graph.jpg"
      />
      <main className="flex-1 flex flex-col items-center px-4 py-16 max-w-2xl mx-auto">
        <h1 className="text-7xl font-light mb-16">Get in Touch</h1>
        
        <img 
          src="/images/theo.jpg" 
          alt="Theo Goodman - Software Developer, Product Manager and Co-founder of Half Lemons" 
          className="w-full max-w-2xl mb-8 rounded-2xl"
        />
        
        {submitStatus === 'success' ? (
          <div className="w-full p-6 mb-8 bg-green-50 border border-green-500 rounded-md text-green-700">
            <h3 className="text-xl font-medium mb-2">Message sent successfully!</h3>
            <p>Thank you for reaching out. I'll get back to you as soon as possible.</p>
            <button 
              onClick={() => setSubmitStatus('idle')}
              className="mt-4 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
            >
              Send another message
            </button>
          </div>
        ) : (
          <form ref={formRef} onSubmit={handleSubmit} className="w-full space-y-6">
            {submitStatus === 'error' && (
              <div className="p-4 mb-4 bg-red-50 border border-red-500 rounded-md text-red-700">
                Failed to send message. Please try again.
              </div>
            )}
            
            <div>
              <label htmlFor="message" className="block text-sm mb-2">
                Message <span className="text-gray-400">(required)</span>
              </label>
              <textarea
                id="message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                rows={6}
                className="w-full p-3 bg-white text-black border border-gray-600 rounded-md focus:outline-none focus:border-white transition-colors"
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm mb-2">
                Email Address <span className="text-gray-400">(required)</span>
              </label>
              <input
                type="email"
                id="email"
                name="from_email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full p-3 bg-white text-black border border-gray-600 rounded-md focus:outline-none focus:border-white transition-colors"
              />
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className={`px-8 py-3 border border-white hover:bg-white hover:text-black transition-colors rounded-md ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {isSubmitting ? 'SENDING...' : 'SUBMIT'}
            </button>
          </form>
        )}
      </main>
      
      <Footer />
    </>
  );
}