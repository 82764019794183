import { useEffect } from 'react';

interface MetaTagsProps {
  title: string;
  description: string;
  path: string; // e.g., "", "about", "contact"
  image?: string;
  lang?: string;
  alternateLanguages?: {
    [key: string]: string;
  };
}

// Define types for meta tags
interface MetaTag {
  [key: string]: string | undefined;  // Allow undefined values
  rel?: string;
  href?: string;
  name?: string;
  content?: string;
  property?: string;
}

export function MetaTags({ 
  title, 
  description, 
  path, 
  image,
  lang = 'en',
  alternateLanguages = {}
}: MetaTagsProps) {
  const siteUrl = 'https://www.hellogoodman.com';
  const fullUrl = `${siteUrl}/${path}`.replace(/\/\/$/, '/');
  const imageUrl = image ? `${siteUrl}${image}` : undefined;

  useEffect(() => {
    // Set title and description
    document.title = title;
    
    // Set HTML lang attribute
    document.documentElement.setAttribute('lang', lang);
    
    // Create and set meta tags
    const tags: MetaTag[] = [
      // Basic Meta
      {
        name: "description",
        content: description
      },
      // Canonical URL
      {
        rel: "canonical",
        href: fullUrl
      },
      // Open Graph
      {
        property: "og:site_name",
        content: "Theo Goodman"
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: fullUrl
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:locale",
        content: "en_US"
      },
      // Twitter Card
      {
        name: "twitter:card",
        content: "summary_large_image"
      },
      {
        name: "twitter:title",
        content: title
      },
      {
        name: "twitter:description",
        content: description
      },
      {
        name: "twitter:creator",
        content: "@theogoodman" // Update with your Twitter handle
      },
      // Additional SEO
      {
        name: "author",
        content: "Theo Goodman"
      },
      {
        name: "keywords",
        content: "Theo Goodman, Software Developer, Product Manager, iOS Developer, Half Lemons, Tech Entrepreneur"
      },
      // Favicon tags
      // {
      //   rel: "icon",
      //   href: "/images/favicon.ico"
      // },
      // {
      //   rel: "icon",
      //   type: "image/png",
      //   sizes: "32x32",
      //   href: "/images/favicon-32.png"
      // },
      // {
      //   rel: "apple-touch-icon",
      //   href: "/images/apple-touch-icon.png"
      // },
      // {
      //   rel: "manifest",
      //   href: "/manifest.json"
      // },
      // Theme color
      {
        name: "theme-color",
        content: "#000000"
      }
    ];

    // Add image tags if image is provided
    if (imageUrl) {
      tags.push(
        {
          property: "og:image",
          content: imageUrl
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          name: "twitter:image",
          content: imageUrl
        }
      );
    }

    // Add alternate language tags
    Object.entries(alternateLanguages).forEach(([langCode, url]) => {
      tags.push({
        rel: "alternate",
        hreflang: langCode,
        href: url
      });
    });

    // Apply all meta tags
    tags.forEach(tag => {
      let element: HTMLElement;
      if (tag.rel === "canonical") {
        element = document.createElement("link");
        (element as HTMLLinkElement).rel = tag.rel;
        (element as HTMLLinkElement).href = tag.href!;
      } else {
        element = document.createElement("meta");
        Object.keys(tag).forEach(key => {
          if (key in tag && tag[key] !== undefined) {
            element.setAttribute(key, tag[key]!);
          }
        });
      }
      document.head.appendChild(element);
    });

    // Cleanup function to remove tags when component unmounts
    return () => {
      document.title = '';
      document.documentElement.removeAttribute('lang');
      document.head.querySelector(`link[rel="canonical"]`)?.remove();
      tags.forEach(tag => {
        const selector = tag.property ? 
          `meta[property="${tag.property}"]` : 
          `meta[name="${tag.name}"]`;
        document.head.querySelector(selector)?.remove();
      });
    };
  }, [title, description, path, imageUrl, lang, alternateLanguages]);

  return null;
} 